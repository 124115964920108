import React from "react"

import SiteLayout from "../components/SiteLayout"
import Seo from "../components/Seo"
import Hero from "../components/Hero"
import Services from "../components/Services"

const NotFoundPage = ({ location }) => (
  <SiteLayout location={{ location }}>
    <Seo title="404: Not found" />

    <Hero slogan="Error 404. Perhaps try one of these..." halfHeight />

    <Services noTitle />
  </SiteLayout>
)

export default NotFoundPage
