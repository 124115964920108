import React, { useState, useEffect } from "react"
import { Waypoint } from "react-waypoint"
import { useIsJsEnabled } from 'gatsby-plugin-js-fallback'

const Reveal = props => {
  const [revealed, setRevealed] = useState(false)
  const {
    delay,
    children,
    classList
  } = props

  useEffect(() => {
    setRevealed(false)
    return (
      setRevealed(false)
    )
  }, [])

  const handleReveal = () => {
    setRevealed(true)
  }

  const isJsEnabled = useIsJsEnabled();
  const setOpacity = revealed ? 1 : 0
  const setTransform = revealed ? `translateY(0%)` : `translateY(20%)`

  return (
      <Waypoint
        onEnter={handleReveal}
        scrollableAncestor={typeof window !== "undefined" ? window : null}
      >
        <div
          className={classList}
          style={{
            clear: `both`,
            display: `block`,
            opacity: isJsEnabled ? setOpacity : 1,
            transform: isJsEnabled ? setTransform : `translateY(0%)`,
            transition: `opacity .3s ease-in-out ${parseFloat(delay)}s, transform 1s ease-in-out`,
          }}
        >
          {children}
        </div>
      </Waypoint>
    )
}

export default Reveal
