import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"

import ScrollMarker from "../ScrollMarker"
import fadeToggle from "../../utils/fadeToggle"

const Hero = props => {
  const [reveal, setReveal] = useState(false)
  const [windowW, setWindowW] = useState(0)
  const [heroHeight, setHeroHeight] = useState(null)
  const sectionHero = useRef(null)
  const fadeTriggerRef = useRef(null)
  const {
    transitionStatus,
    initialSiteLoad,
    slogan,
    bgColour,
    halfHeight,
    heroImage,
    heroImageStatic,
    scrollMarker,
  } = props

  useEffect(() => {
    // Signify a time for our hero content to animate in
    if (transitionStatus === "entered") {
      setTimeout(
        () => {
          setReveal(true)
        },
        initialSiteLoad ? 2000 : 500
      )
    }
  }, [transitionStatus])

  useEffect(() => {
    fadeToggle(sectionHero.current, fadeTriggerRef.current)
  }, [])

  useEffect(() => {
    // on component mount get the space between hero and bottom of viewport
    setHeroHeight(window.outerHeight - sectionHero.current.clientHeight)

    setTimeout(() => {
      // Remove this height (which reveals content below with a transition)
      setHeroHeight(null)
    }, 750)
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowW(window.innerWidth)
    }
  }, [])

  return (
    <>
      <section
        className={`section section--hero ${
          halfHeight ? "" : "section--hero--full-height"
        }`}
        style={{
          background: bgColour,
          paddingBottom: halfHeight ? heroHeight : null, // use of this with transition creates the sliding content animation
          transition:
            halfHeight && heroHeight === null
              ? `padding-bottom 1s cubic-bezier(0.645, 0.045, 0.355, 1.000)`
              : null,
        }}
        ref={sectionHero}
      >
        <div
          className="container-fluid"
          style={{ position: `relative`, zIndex: 2 }}
        >
          <div className="row">
            <div
              className={`col-bp1-12 col-bp2-8 ${
                halfHeight ? `col-bp3-8` : `col-bp4-7 col-bp7-6`
              }`}
            >
              <h1
                className="intro-title intro-title--hero"
                dangerouslySetInnerHTML={{ __html: slogan }}
                style={{
                  animationDelay: `1s`,
                }}
              />
            </div>
          </div>
        </div>

        {windowW >= 768 ? heroImage : null}
        {heroImageStatic}
        {scrollMarker ? (
          <ScrollMarker reveal={reveal} variant="bottom" />
        ) : null}
      </section>
      <div className="fade-trigger" ref={fadeTriggerRef} />
    </>
  )
}

export default connect(
  state => ({
    initialSiteLoad: state.initialSiteLoad.initialSiteLoad,
  }),
  null
)(Hero)
