import React from "react"
import Reveal from "../Waypoints"

import Title from "../Title"
import ServiceSubNav from "./ServiceSubNav"

const Services = props => {
  return (
    <section className={`section section--black ${props.noSpaceBottom ? `section--no-space-bottom` : null}`}>
      <div className="container-fluid">
        {!props.noTitle ? (
          <div className="row">
            <div className="col-bp1-12">
              <Reveal delay="0.5">
                <Title>Our Services</Title>
              </Reveal>
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-bp1-12 col-bp4-6">
            <Reveal delay="0.5">
              <ServiceSubNav service="creative" lightMode={true} url />
            </Reveal>
          </div>
          <div className="col-bp1-12 col-bp4-6">
            <Reveal delay="0.75">
              <ServiceSubNav service="communications" lightMode={true} url />              
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
