import React from 'react'

import PageLink from "../PageLink"
import Chevron from "../Symbols/chevron"

const SubNav = props => {
  // Element classes determined by whether dropdown is active or not
  const lightMode = props.lightMode ? `card--light-mode` : ``
  const last = props.last ? `card--last` : ``
  return (
    <div className={`card ${lightMode} ${last}`}>
      <div className={`card__header ${props.url ? `card__header--link` : ``}`}>
        {props.url ? <PageLink 
          url={props.url}
          colour={props.colour}
          cursor="alt">
          {props.title} <Chevron />
        </PageLink> : <h2 className="card__title">{props.title}</h2>
        }
      </div>
      <div className="card__body">{props.children}</div>
    </div>
  )
}

export default SubNav