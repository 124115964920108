import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SubNav from "../SubNav"
import Chevron from "../Symbols/chevron"
import PageLink from "../PageLink"

const ServiceSubNav = props => {
  // get all child items, for Creative and Comms and loop
  const data = useStaticQuery(graphql`
    query CHILD_PAGES {
      allWpPage {
        edges {
          node {
            title
            uri
            pageTemplate
            wpParent {
              node {
                id
                slug
              }
            }
          }
        }
      }
    }
  `)

  if (data) {
    return (
      <SubNav
        title={props.service}
        url={props.url ? `/${props.service}` : false}
        lightMode={props.lightMode}
        colour={props.service === `creative` ? `blue` : `green`}
        last
      >
        <div className="card__desc">
          <nav className={`subnav subnav--${props.service.toLowerCase()}`}>
            <ul>
              {data.allWpPage.edges
                .filter(item => {
                  return item.node.pageTemplate === `templates/child.php`
                })
                .map((item, i) => {
                  const parent = item.node.wpParent.node.slug
                  if (parent === props.service.toLowerCase()) {
                    return (
                      <li key={i}>
                        <PageLink
                          url={item.node.uri}
                          dispatch={props.dispatch}
                          colour={parent === `creative` ? `blue` : `green`}
                          menuLink={false}
                        >
                          {item.node.title} <Chevron />
                        </PageLink>
                      </li>
                    )
                  }
                })}
            </ul>
          </nav>
        </div>
      </SubNav>
    )
  }

  return false
}

export default ServiceSubNav
